export const gitHubLogo = (color = "var(--c-black") => {
    return `
        <svg width="100%" height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z" fill="${color}"/>
        </svg>
        `
}

// export const linkedIn = (color: string) => {
//     return `
//         <svg xmlns="http://www.w3.org/2000/svg" height="72" viewBox="0 0 72 72" width="72">
//             <g fill="none" fill-rule="evenodd">
//                 <path d="M8,72 L64,72 C68.418278,72 72,68.418278 72,64 L72,8 C72,3.581722 68.418278,-8.11624501e-16 64,0 L8,0 C3.581722,8.11624501e-16 -5.41083001e-16,3.581722 0,8 L0,64 C5.41083001e-16,68.418278 3.581722,72 8,72 Z" fill="#007EBB"/>
//                 <path d="M62,62 L51.315625,62 L51.315625,43.8021149 C51.315625,38.8127542 49.4197917,36.0245323 45.4707031,36.0245323 C41.1746094,36.0245323 38.9300781,38.9261103 38.9300781,43.8021149 L38.9300781,62 L28.6333333,62 L28.6333333,27.3333333 L38.9300781,27.3333333 L38.9300781,32.0029283 C38.9300781,32.0029283 42.0260417,26.2742151 49.3825521,26.2742151 C56.7356771,26.2742151 62,30.7644705 62,40.051212 L62,62 Z M16.349349,22.7940133 C12.8420573,22.7940133 10,19.9296567 10,16.3970067 C10,12.8643566 12.8420573,10 16.349349,10 C19.8566406,10 22.6970052,12.8643566 22.6970052,16.3970067 C22.6970052,19.9296567 19.8566406,22.7940133 16.349349,22.7940133 Z M11.0325521,62 L21.769401,62 L21.769401,27.3333333 L11.0325521,27.3333333 L11.0325521,62 Z" fill="#FFF"/>
//             </g>
//         </svg>
//     `
// }

export const linkedInLogo = (color = "var(--c-black") => {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 72 72" >
            <g fill="none" fill-rule="evenodd">
                <path d="M8,72 L64,72 C68.418278,72 72,68.418278 72,64 L72,8 C72,3.581722 68.418278,-8.11624501e-16 64,0 L8,0 C3.581722,8.11624501e-16 -5.41083001e-16,3.581722 0,8 L0,64 C5.41083001e-16,68.418278 3.581722,72 8,72 Z" fill="${color}"/>
                <path d="M62,62 L51.315625,62 L51.315625,43.8021149 C51.315625,38.8127542 49.4197917,36.0245323 45.4707031,36.0245323 C41.1746094,36.0245323 38.9300781,38.9261103 38.9300781,43.8021149 L38.9300781,62 L28.6333333,62 L28.6333333,27.3333333 L38.9300781,27.3333333 L38.9300781,32.0029283 C38.9300781,32.0029283 42.0260417,26.2742151 49.3825521,26.2742151 C56.7356771,26.2742151 62,30.7644705 62,40.051212 L62,62 Z M16.349349,22.7940133 C12.8420573,22.7940133 10,19.9296567 10,16.3970067 C10,12.8643566 12.8420573,10 16.349349,10 C19.8566406,10 22.6970052,12.8643566 22.6970052,16.3970067 C22.6970052,19.9296567 19.8566406,22.7940133 16.349349,22.7940133 Z M11.0325521,62 L21.769401,62 L21.769401,27.3333333 L11.0325521,27.3333333 L11.0325521,62 Z" fill="#FFF"/>
            </g>
        </svg>
    `
}

export const personalSiteSvgContent = ()=> {
    return`
            <g clip-path="url(#clip0_107_478)">
                <rect width="2560" height="1600" fill="white"/>
            <g clip-path="url(#clip1_107_478)">
            <mask id="mask0_107_478" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="2693" height="1600">
            <path d="M2693 0H0V1600H2693V0Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_107_478)">
                <path d="M2693 0H0V1600H2693V0Z" fill="white"/>
                <path d="M0 40.8979H2693" stroke="#8C8C8C"/>
                <path d="M0 116.708H2693" stroke="#8C8C8C"/>
                <path d="M0 192.519H2693" stroke="#8C8C8C"/>
                <path d="M0 270.324H2693" stroke="#8C8C8C"/>
                <path d="M0 344.14H2693" stroke="#8C8C8C"/>
                <path d="M0 419.95H2693" stroke="#8C8C8C"/>
                <path d="M0 495.761H2693" stroke="#8C8C8C"/>
                <path d="M0 569.576H2693" stroke="#8C8C8C"/>
                <path d="M0 647.381H2693" stroke="#8C8C8C"/>
                <path d="M0 723.192H2693" stroke="#8C8C8C"/>
                <path d="M0 800.998H2693" stroke="#8C8C8C"/>
                <path d="M0 874.813H2693" stroke="#8C8C8C"/>
                <path d="M0 950.624H2693" stroke="#8C8C8C"/>
                <path d="M0 1028.43H2693" stroke="#8C8C8C"/>
                <path d="M0 1104.24H2693" stroke="#8C8C8C"/>
                <path d="M0 1180.05H2693" stroke="#8C8C8C"/>
                <path d="M0 1253.87H2693" stroke="#8C8C8C"/>
                <path d="M0 1329.68H2693" stroke="#8C8C8C"/>
                <path d="M0 1407.48H2693" stroke="#8C8C8C"/>
                <path d="M0 1481.3H2693" stroke="#8C8C8C"/>
                <path d="M0 1559.1H2693" stroke="#8C8C8C"/>
                <path d="M2029.72 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M2107.52 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M1953.92 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M1880.11 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M1802.32 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M1726.51 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M1648.71 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M1574.91 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M2560.34 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M2484.54 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M2408.74 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M2332.94 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M2257.13 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M2183.32 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M1499.1 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M1421.31 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M1345.5 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M1271.69 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M1195.89 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M1120.09 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M1044.29 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M968.482 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M890.685 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M814.882 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M741.074 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M663.276 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M587.473 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M509.675 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M437.862 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M362.059 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M284.261 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M208.458 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M132.655 5.98486V1605.98" stroke="#8C8C8C"/>
                <path d="M56.8525 5.98486V1605.98" stroke="#8C8C8C"/>
            </g>
        </g>
        </g>
        <defs>
            <clipPath id="clip0_107_478">
            <rect width="2560" height="1600" fill="white"/>
            </clipPath>
            <clipPath id="clip1_107_478">
            <rect width="2693" height="1600" fill="white"/>
            </clipPath>
        </defs>
    `

}